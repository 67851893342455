<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-show="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <label>Region</label>
          <v-select
            v-model="region"
            label="name"
            placeholder="Pilih Region"
            :options="optionRegion"
            @input="changeSelectRegion"
          />
        </b-col>
       
        <b-col
          cols="12"
          class="mt-2"
        >
          <b-button
            class="mr-1"
            variant="outline-secondary"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
          <b-button
            class="mr-1"
            variant="primary"
            type="button"
            @click.prevent="getFilter()"
          >Filter</b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card v-if="renderComp">
      <basetable
        ref="basetable"
        :dataurl="dataurl+'/'+user.id+'/spb?status=prepared'"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :custom_filter="custom_filter"
        :tombol_hide="true"
        :is-detail="isDetail"
        :is-add="true"
        :is-edit="true"
        :is-delete="true"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="float-right"
            variant="info"
            @click="showFilter =!showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            Filter
          </b-button>
        </template>
        <template #cell(number)="data">
          {{ data.item.number }}
          <br>
          <small class="text-muted">{{ data.item.date }}</small>
        </template>
        <template #cell(status)="data">
          <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'created'">Pembuatan SPB</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'prepared'">Penyiapan Unit Oleh Vendor</span></center>
          <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'applied'">Pengajuan SPB</span></center>
          <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'delivered'">Pengiriman SPB Ke Vendor</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'viewed'">SPB Ditinjau</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'approved'">SPB Ditinjau</span></center>
          <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'canceled'">Dibatalkan</span></center>
          <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'returned'">SPB Direvisi</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'received'">Pengiriman Unit</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'distributed'">Pengiriman Unit dari Vendor</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'request_rikmatek'">Pengajuan Rikmatek</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'checked'">Sudah Diperiksa</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'send_letter_distribute'">Sudah Pemeriksaan</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_published'">BAPP Dipublish</span></center>
          <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_requested'">BAPP Direquest</span></center>
        </template>
        <template #cell(action)="data">
          <center>
            <b-button
              variant="flat-success"
              class="waves-effect btn-icon"
              @click="btnDetail(data.item)"
            >
              <feather-icon
                icon="FolderIcon"
              />
            </b-button>
          </center>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import { BCard,BButton,BRow,BCol, } from 'bootstrap-vue'
import FormSelectPopup from '@/views/base/form/FormSelectPopup.vue'
import FormDateRangeMonth from '@/views/base/form/FormDateRangeMonth.vue'
import vSelect from 'vue-select'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    vSelect,
    FormSelectPopup,
    BRow,
    BCol,
    FormDateRangeMonth
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/vendor",
      baseroute:"persiapan-unit",
      title:"Daftar Persiapan Unit",
      fields: [
        { key: 'number', label: 'No SPB', sortable: true },
        { key: 'region.name', label: 'Region', sortable: true },
        { key: 'contract_subject', label: 'Judul Kontrak', sortable: true },
        { key: 'contract_number', label: 'No Kontrak', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      custom_filter : true,
      isDetail : false,
      renderComp : true,
      isDelete : false,
      isEdit : false,
      optionRegion :[],
      region:[],
      filter:[],
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
  },
  mounted(){
    this.getRegion()
    this.filter.status = 'approved'
  },
  methods:{
    getRegion(){
      this.$http.get('region').then(res=>{
        this.optionRegion = res.data.data
      })
    },
    show(){
      this.$refs.basetable.fetchData()
    },
    changeSelectRegion(data){
      if(data != null){
        this.filter['filter[branch_id]'] = data.id
        this.region.id = data.id
        this.region.name = data.name
      }
    },
    getFilter(){
      this.renderComp = false
      this.$nextTick().then(() => {
        this.renderComp = true
      })
    },
    reset(){
      this.region = []
      this.status = []
      this.filter=[]
      this.renderComp = false
      this.$nextTick().then(() => {
        this.renderComp = true
      })
    },
    btnDetail(data){
      this.$router.push({ name: 'persiapan-unit-vendor-show',params : { id: data.id} })
    }
  }
}
</script>
<style lang="">

</style>
